<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12" md="10" lg="10" offset-md="1" offset-lg="1">
          <div
            style="
              width: 100%;
              height: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ;
            "
          >
            <div
              style="
                width: 60%;
                height: 360px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: 30px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                flex-direction: column;
              "
            >
              <h1 style="font-size: 48px" class="mt-0 mb-0">
                <strong>Job Vacancy</strong>
              </h1>
              <h1 style="font-size: 48px" class="mt-0 mb-0">
                <strong>Now We Are Hiring</strong>
              </h1>
              <b-form-group
                id="input-group-3"
                label="Silahkan masukan token yang anda miliki"
                label-for="input-3"
                style="width: 100%"
                class="mt-5"
              >
                <b-form-input
                  type="text"
                  v-model="tokenPelamar"
                  @keydown.enter.prevent="login()"
                ></b-form-input>
              </b-form-group>

              <b-alert
                    dismissible
                    fade
                    :show="show"
                    :variant="variant"
                    >{{ msg }}</b-alert
                  >
              <b-button variant="primary" @click="login()">Cari</b-button>
            </div>
            <div
              style="
                width: 40%;
                height: 360px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <img src="img/ilustrasi-job.png" alt="" style="width: 70%" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  data() {
    return {
      tokenPelamar: "",
      msg: "",
      variant:"",
      show: false
    };
  },
  methods: {
    async login() {
      let vm = this;
      let login = await axios.post(ipBackend + "postLoker/loginPelamar", {
        tokenPelamar: vm.tokenPelamar,
      });
      if (login.data.status == 200) {
        if (login.data.message == "sukses") {
          vm.$router.push({ path: "/dokumenLoker/" + login.data.data[0].id });
        } else {
          vm.msg = login.data.message.toUpperCase();
          vm.variant = "danger";
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 3000);
        }
      } else {
        vm.msg = login.data.message.toUpperCase();
        vm.variant = "danger";
        vm.show = true;
        setTimeout(() => {
            vm.show = false;
          }, 3000);
      }
    },
  },
};
</script>
